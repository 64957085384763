import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { Business } from "./Business";

import { Container, Businesses, Sector, Button } from "./style";
import { BiWalk } from "react-icons/bi";
import { AiFillCloseCircle } from "react-icons/ai";
import { useState } from "react";
import selectAll from "../../../../assets/select-all.png";

SwiperCore.use([Navigation]);

export function SectorsAndBusiness({
	sectors,
	business,
	fetchBusinessDatas,
	nextPage,
	fetchMoreBusiness,
	loadingMoreBusiness,
	sectorSelected,
	fetchFoodParks,
	fetchFoodParksDatas,
	fetchSectors,
	hasFoodParks,
}) {
	const location = JSON.parse(localStorage.getItem("@Inoveclube:location"));
	// Filtros selecionados
	const [filtersSelected, setFiltersSelected] = useState({
		openStore: false,
		freeDeliveryActive: false,
		freeDeliveryDsBairro: location.neighborhood,
		takeAway: false,
		coupons: false,
		fidelity: false,
	});

	return (
		<Container>
			{hasFoodParks && (
				<div className="btn-wrapper">
					<button
						onClick={() => {
							setFiltersSelected({
								openStore: false,
								freeDelivery: {
									dsBairro: location.neighborhood,
									active: false,
								},
								takeAway: false,
								coupons: false,
								fidelity: false,
							});
							fetchSectors(
								JSON.parse(localStorage.getItem("@Inoveclube:location"))
									.idCity,
								true
							);
						}}
						className={sectorSelected !== "foodpark" ? "selected" : ""}
					>
						Restaurantes
					</button>
					<button
						className={sectorSelected === "foodpark" ? "selected" : ""}
						onClick={fetchFoodParks}
					>
						Food Parks
					</button>
				</div>
			)}
			{sectors.length === 0 ? (
				<h2 className="advice">Nenhuma loja cadastrada!</h2>
			) : (
				<>
					{sectorSelected === "foodpark" ? (
						<>
							<Swiper
								slidesPerView={"auto"}
								spaceBetween={10}
								style={{ padding: "1.5rem 0" }}
							>
								{sectors.map((section) => (
									<SwiperSlide
										key={section.idSector}
										className="swiper-wrapper"
									>
										<Sector
											onClick={() =>
												fetchFoodParksDatas(section.idSector)
											}
										>
											<img
												className="img-wrapper"
												src={section.linkImage}
												alt=""
											/>
											<p>{section.sector}</p>
										</Sector>
									</SwiperSlide>
								))}
							</Swiper>
						</>
					) : (
						<>
							<Swiper
								slidesPerView={"auto"}
								spaceBetween={10}
								style={{ padding: "1.5rem 0" }}
							>
								<SwiperSlide className="swiper-wrapper">
									<Sector
										onClick={() =>
											fetchBusinessDatas(-1, filtersSelected)
										}
										img={selectAll}
									>
										<p>TODOS</p>
									</Sector>
								</SwiperSlide>

								{sectors.map((section) => (
									<SwiperSlide
										key={section.idSector}
										className="swiper-wrapper"
									>
										<Sector
											onClick={() =>
												fetchBusinessDatas(
													section.idSector,
													filtersSelected
												)
											}
											img={section.linkImage}
										>
											<p>{section.sector}</p>
										</Sector>
									</SwiperSlide>
								))}
							</Swiper>

							<Swiper
								slidesPerView={"auto"}
								spaceBetween={15}
								style={{ padding: "1rem 0" }}
							>
								<SwiperSlide
									className={`filters-swiperSlide ${
										filtersSelected.openStore ? "selected" : ""
									}`}
									onClick={() => {
										const newFilters = {
											...filtersSelected,
											openStore: !filtersSelected.openStore,
										};
										setFiltersSelected(newFilters);
										fetchBusinessDatas(sectorSelected, newFilters);
									}}
								>
									Lojas abertas
									{filtersSelected.openStore ? (
										<AiFillCloseCircle className="close-icon" />
									) : (
										<></>
									)}
								</SwiperSlide>
								{location.neighborhood && (
									<SwiperSlide
										className={`filters-swiperSlide ${
											filtersSelected.freeDeliveryActive
												? "selected"
												: ""
										}`}
										onClick={() => {
											const newFilters = {
												...filtersSelected,
												freeDeliveryActive:
													!filtersSelected.freeDeliveryActive,
											};
											setFiltersSelected(newFilters);
											fetchBusinessDatas(
												sectorSelected,
												newFilters
											);
										}}
									>
										Entrega Grátis
										{filtersSelected.freeDeliveryActive ? (
											<AiFillCloseCircle className="close-icon" />
										) : (
											<></>
										)}
									</SwiperSlide>
								)}
								<SwiperSlide
									className={`filters-swiperSlide ${
										filtersSelected.takeAway ? "selected" : ""
									}`}
									onClick={() => {
										const newFilters = {
											...filtersSelected,
											takeAway: !filtersSelected.takeAway,
										};
										setFiltersSelected(newFilters);
										fetchBusinessDatas(sectorSelected, newFilters);
									}}
								>
									<BiWalk size={16} />
									Pra retirar
									{filtersSelected.takeAway ? (
										<AiFillCloseCircle className="close-icon" />
									) : (
										<></>
									)}
								</SwiperSlide>
								<SwiperSlide
									className={`filters-swiperSlide ${
										filtersSelected.coupons ? "selected" : ""
									}`}
									onClick={() => {
										const newFilters = {
											...filtersSelected,
											coupons: !filtersSelected.coupons,
										};
										setFiltersSelected(newFilters);
										fetchBusinessDatas(sectorSelected, newFilters);
									}}
								>
									Lojas com cupons
									{filtersSelected.coupons ? (
										<AiFillCloseCircle className="close-icon" />
									) : (
										<></>
									)}
								</SwiperSlide>
								<SwiperSlide
									className={`filters-swiperSlide ${
										filtersSelected.fidelity ? "selected" : ""
									}`}
									onClick={() => {
										const newFilters = {
											...filtersSelected,
											fidelity: !filtersSelected.fidelity,
										};
										setFiltersSelected(newFilters);
										fetchBusinessDatas(sectorSelected, newFilters);
									}}
								>
									Lojas com fidelidade
									{filtersSelected.fidelity ? (
										<AiFillCloseCircle className="close-icon" />
									) : (
										<></>
									)}
								</SwiperSlide>
								<SwiperSlide
									className="filters-swiperSlide"
									onClick={() => {
										setFiltersSelected({
											openStore: false,
											freeDelivery: {
												dsBairro: location.neighborhood,
												active: false,
											},
											takeAway: false,
											coupons: false,
											fidelity: false,
										});
										fetchBusinessDatas(sectorSelected);
									}}
								>
									Limpar
								</SwiperSlide>
							</Swiper>
						</>
					)}
					<Businesses>
						{business.length === 0 ? (
							<p className="none">Nenhuma loja encontrada!</p>
						) : (
							<>
								{business.map((business) => (
									<Business
										key={business.idBusiness}
										business={business}
									/>
								))}
							</>
						)}
					</Businesses>

					{nextPage !== null && (
						<Button
							onClick={fetchMoreBusiness}
							loadingMoreBusiness={loadingMoreBusiness}
						>
							{loadingMoreBusiness ? "Pesquisando..." : "Ver mais"}
						</Button>
					)}
				</>
			)}
		</Container>
	);
}
