import { useEffect, useState } from "react"
import { ChoseDate } from "../choseDate"
import { ChoseTime } from "../choseTime"

export function Indeterminate({ schedulingData }) {
	// States
	const [currentStep, setCurrentStep] = useState("date")
	const [day, setDay] = useState(null)
	const [avaliableTimes, setAvailableTimes] = useState([])
	// Effects
	useEffect(() => {
		if (day) {
			const timesToday = schedulingData.scheduling.schedulingTimes.filter(
				(time) => time.weekDay === day
			)
			setAvailableTimes(
				schedulingData.generateTimesInterval({
					schedules: timesToday,
				})
			)
		}
	}, [day, schedulingData])

	// ==== Return ====
	return (
		<>
			{
				{
					date: (
						<ChoseDate
							setCurrentStep={setCurrentStep}
							setDay={setDay}
							day={day}
							toClose={schedulingData.toClose}
							setSchedulingDate={schedulingData.setSchedulingDate}
						/>
					),
					time: (
						<ChoseTime
							availableTimes={avaliableTimes}
							schedulingData={schedulingData}
							toClose={schedulingData.toClose}
						/>
					),
				}[currentStep]
			}
		</>
	)
}
