import styled from "styled-components"

export const Container = styled.div`
    height: 100vh;
    width: 100vw;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    .dividing{
        background: #7A05A7;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0 1rem 1rem 0;

        img{
            height: 8rem;
        }

        @media (max-width: 800px){
            display: none;
        }
    }

    @media (max-width: 800px){
        grid-template-columns: 1fr;
    }
`