import { useEffect, useState } from "react";
import { SmallModal } from "../../../../components/smallModal";
import { api } from "../../../../services/api";
import { useOrderManagerContext } from "../../context";
import { OrderCard } from "../orderCard";
import { Container } from "./style";

export function SchedulingModal() {
  const { companies, showSchedulingModal, setShowSchedulingModal } = useOrderManagerContext();

  const [purchases, setPurchases] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const selectedCompanies = companies.filter((item) => item.selected === true);
      if (selectedCompanies.length === 0) return;
      const cdEmpresaToQuery = selectedCompanies.map((item) => item.cdEmpresa);
      const { data } = await api.get("/getPurchaseRequests/schedule", {
        params: {
          companies: cdEmpresaToQuery.join(","),
        },
      });
      setIsLoading(false);
      setPurchases(data);
    })();
  }, [companies]);

  if (!showSchedulingModal) return null;
  return (
    <SmallModal
      title="Pedidos Agendados"
      toClose={() => setShowSchedulingModal(false)}
      loading={isLoading}
    >
      <Container>
        {purchases.length === 0 && <h3>Nenhum pedido agendado</h3>}
        {purchases.map((item) => {
          return <OrderCard isSchedule key={item.idOrcamento} item={item} />;
        })}
      </Container>
    </SmallModal>
  );
}
