import { useEffect, useState } from "react"
import { ChoseDay } from "../choseDay"
import { ChoseTime } from "../choseTime"

export function Weekly({ schedulingData }) {
	// States
	const [currentStep, setCurrentStep] = useState("day")
	const [day, setDay] = useState(null)
	const [avaliableTimes, setAvailableTimes] = useState([])
	// Effects
	useEffect(() => {
		if (day) {
			const timesToday = schedulingData.scheduling.schedulingTimes.filter(
				(time) => time.weekDay === day
			)
			setAvailableTimes(
				schedulingData.generateTimesInterval({
					schedules: timesToday,
				})
			)
		}
	}, [day, schedulingData])
	// ==== Return ====
	return (
		<>
			{
				{
					day: (
						<ChoseDay
							setCurrentStep={setCurrentStep}
							availableDays={schedulingData.scheduling.schedulingTimes}
							day={day}
							setDay={setDay}
							toClose={schedulingData.toClose}
							setSchedulingDate={schedulingData.setSchedulingDate}
						/>
					),
					time: (
						<ChoseTime
							availableTimes={avaliableTimes}
							schedulingData={schedulingData}
							toClose={schedulingData.toClose}
							day={day}
						/>
					),
				}[currentStep]
			}
		</>
	)
}
