import moment from "moment"
import { Button } from "../../../../../../components/Button"
import { SmallModal } from "../../../../../../components/smallModal"
import { Container } from "./style"
import { toast } from "react-toastify"

export function ChoseDate({
	setCurrentStep,
	day,
	setDay,
	toClose,
	setSchedulingDate,
}) {
	return (
		<SmallModal title="Agendamento de pedido" toClose={toClose}>
			<Container>
				<input
					type="date"
					name="date"
					id="date"
					onChange={(e) => {
						if (moment().format("YYYY-MM-DD") === e.target.value) {
							setDay(
								moment(e.target.value)
									.utcOffset(0)
									.format("LLLL")
									.split(",")[0]
									.toLocaleLowerCase()
							)
							setSchedulingDate(e.target.value)
							return
						}
						if (moment().isAfter(e.target.value)) {
							setDay(null)
							toast.warning("Escolha uma data válida")
							return
						}
						setDay(
							moment(e.target.value)
								.utcOffset(0)
								.format("LLLL")
								.split(",")[0]
								.toLocaleLowerCase()
						)
						setSchedulingDate(e.target.value)
					}}
				/>
				<Button
					className="button"
					disabled={!day}
					onClick={() => setCurrentStep("time")}
				>
					Próximo
				</Button>
			</Container>
		</SmallModal>
	)
}
