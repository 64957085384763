import { useEffect, useState } from "react";
import { Button } from "../../../../components/Button";
import { RadioWithLabel } from "../../../../components/radioWithLabel";
import { SmallModal } from "../../../../components/smallModal";
import { Container } from "./style";
import { toast } from "react-toastify";
import { SchedulingModal } from "../schedulingModal";
import { api } from "../../../../services/api";
import moment from "moment";

export function ChoseOrderTypeModal({ scheduling, storeIsOpen, toClose, cdEmpresa }) {
	// States
	const [choice, setChoice] = useState(storeIsOpen ? "today" : "schedule");
	const [schedulingModalIsOpen, setSchedulingModalIsOpen] = useState(false);
	const [schedulingTime, setSchedulingTime] = useState();
	const [deliveryTime, setDeliveryTime] = useState({ min: "", max: "" });
	const [schedulingDate, setSchedulingDate] = useState(moment().format("YYYY-MM-DD"));
	// Functions
	async function handleNext() {
		if (choice === "today") {
			localStorage.removeItem("@Inoveclube:agendamento");
			if (
				localStorage.getItem(
					"@Inoveclube:listBuying" + window.location.pathname.split("/")[2]
				)
			) {
				if (localStorage.getItem("@Inoveclube:token")) {
					window.location.href = `${process.env.REACT_APP_URL}shopping`;
				} else {
					localStorage.setItem("@Inoveclube:menu", true);
					window.location.href = `${process.env.REACT_APP_URL}login`;
				}
			} else {
				toast.error("Adicione itens ao carrinho para continuar");
			}
			toClose();
			return;
		}
		if (choice === "schedule") {
			if (!schedulingTime) {
				toast.warning("Escolha um horário");
				return;
			}
			localStorage.setItem(
				"@Inoveclube:agendamento",
				JSON.stringify(`${schedulingDate} ${schedulingTime}`)
			);
			window.location.href = `${process.env.REACT_APP_URL}shopping`;
			return;
		}
	}

	function generateTimesInterval({ schedules }) {
		let times = [];

		for (const currentData of schedules) {
			const startDate = new Date(currentData.startTime);
			startDate.setHours(startDate.getHours() + 3);
			const endDate = new Date(currentData.endTime);
			endDate.setHours(endDate.getHours() + 3);

			for (
				let i = startDate;
				i <= endDate;
				i.setMinutes(i.getMinutes() + Number(currentData.interval))
			) {
				const hour =
					i.getHours().toString().length === 1 ? `0${i.getHours()}` : i.getHours();
				const minutes =
					i.getMinutes().toString().length === 1 ? `${i.getMinutes()}0` : i.getMinutes();
				times.push(`${hour}:${minutes}`);
			}
		}
		return times;
	}

	//Effects
	useEffect(() => {
		if (scheduling.length === 0) return;

		(async () => {
			// ---- Buscando tempo de entrega
			const { data: deliveryTimeData } = await api.get(`getDeliveryTime/${cdEmpresa}`);
			setDeliveryTime(deliveryTimeData);
			// ----

			// ---- Selecionando o horário válido mais próximo do horário atual
			let timeFound = false;

			const currentWeekDay = moment()
				.utcOffset(0)
				.format("LLLL")
				.split(",")[0]
				.toLocaleLowerCase();

			const schedulesToday = scheduling.schedulingTimes.filter(
				(schedulingTime) => schedulingTime.weekDay === currentWeekDay
			);

			if (schedulesToday.length > 0) {
				const timesAvaliablesToday = generateTimesInterval({ schedules: schedulesToday });

				const nextAvailableTime = moment()
					.add(deliveryTimeData.max, "minutes")
					.format("HH:mm");

				for (const time of timesAvaliablesToday) {
					if (time >= nextAvailableTime) {
						timeFound = true;
						setSchedulingTime(time);
						setSchedulingDate(moment().format("YYYY-MM-DD"));
						return;
					}
				}
			}

			if (!timeFound) {
				for (let index = 1; index <= 7; index++) {
					const dayToCheck = moment()
						.add(index, "days")
						.format("LLLL")
						.split(",")[0]
						.toLocaleLowerCase();

					for (const schedulingTime of scheduling.schedulingTimes) {
						if (schedulingTime.weekDay === dayToCheck) {
							setSchedulingTime(
								moment(schedulingTime.startTime).utcOffset(0).format("HH:mm")
							);
							setSchedulingDate(moment().add(index, "days").format("YYYY-MM-DD"));
							timeFound = true;
							return;
						}
					}

					if (timeFound) {
						break;
					}
				}
			}
			// ----
		})();
	}, [cdEmpresa, scheduling]);

	// ==== Render ====
	return (
		<>
			{schedulingModalIsOpen ? (
				<SchedulingModal
					scheduling={scheduling}
					toClose={() => setSchedulingModalIsOpen(false)}
					setSchedulingTime={setSchedulingTime}
					generateTimesInterval={generateTimesInterval}
					setSchedulingDate={setSchedulingDate}
					schedulingDate={schedulingDate}
				/>
			) : (
				<SmallModal title="Escolha o horário da entrega" toClose={toClose}>
					<Container>
						{!storeIsOpen && <p className="advice">Loja fechada no momento!</p>}
						<div className="spaced">
							<RadioWithLabel
								label="Hoje"
								checked={choice === "today"}
								onChange={() => setChoice("today")}
								disabled={!storeIsOpen}
							>
								<span>
									Entrega em {deliveryTime.min} a {deliveryTime.max} minutos
								</span>
							</RadioWithLabel>
							<div className="flex">
								<RadioWithLabel
									label="Agendar pedido"
									checked={choice === "schedule"}
									onChange={() => setChoice("schedule")}
								>
									<span>
										{schedulingTime
											? `Entrega ${moment(schedulingDate).format(
													"DD/MM"
											  )} ás ${schedulingTime}`
											: "Escolha um horário"}
									</span>
								</RadioWithLabel>
								<button
									className="underline"
									onClick={() => setSchedulingModalIsOpen(true)}
								>
									Alterar horário
								</button>
							</div>
						</div>
						<Button className="button" onClick={handleNext}>
							Próximo
						</Button>
					</Container>
				</SmallModal>
			)}
		</>
	);
}
