import { BsCreditCard } from "react-icons/bs";
import { FaArrowDown, FaArrowUp, FaMoneyBillAlt } from "react-icons/fa";
import { RiBankLine } from "react-icons/ri";
import { AiFillPrinter } from "react-icons/ai";
import { MdAttachMoney } from "react-icons/md";
import { formatMoney } from "../../../../Utils/formatMoney";
import { Container, RequestConfirmation } from "./style";
import { useOrderManagerContext } from "../../context";
import { useState, useEffect, useCallback } from "react";
import moment from "moment";
import { api } from "../../../../services/api";
import { sincronizadorApi } from "../../../../services/sincronizadorApi";
import { toast } from "react-toastify";
import { FaWhatsappSquare } from "react-icons/fa";
import { getOrderInfos } from "../../requests/orders/getOrderInfos";

export const OrderCard = ({ item, isSchedule }) => {
  // ---- Context
  const { printOrder, companies, handleGetOrders, setMotoboyDialogInfo, setChoseMotoboy } =
    useOrderManagerContext();
  // ---- Local timer for auto acept
  const localTimer = JSON.parse(
    localStorage.getItem(`@inove-clube:timer-${item.idOrcamento}`),
  );
  // ---- States
  const [isRequestConfirmationOpen, setIsRequestConfirmationOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [purchaseDetails, setPurchaseDetails] = useState([]);
  const [isPurchaseDetailLoading, setIsPurchaseDetailLoading] = useState(false);
  const [timerToAutoAceptPurchase, setTimerToAutoAceptPurchase] = useState(
    localTimer || localTimer === 0 ? localTimer : 30,
  );

  // ---- Functions
  const showPurchaseDetails = async () => {
    setIsPurchaseDetailLoading(true);
    if (purchaseDetails.length === 0) {
      const orderInfos = await getOrderInfos(item.idOrcamento);
      setPurchaseDetails(orderInfos);
      setIsPurchaseDetailLoading(false);
      setIsDetailsOpen(!isDetailsOpen);
    } else {
      setIsPurchaseDetailLoading(false);
      setIsDetailsOpen(!isDetailsOpen);
    }
  };

  function orderTypeDescription(type) {
    switch (type) {
      case "D":
        return "Delivery";
      case "M":
        return "Mesa";
      case "B":
        return "Balcão";
      default:
        return "Outros";
    }
  }

  const updateStatusPedido = useCallback(
    async (status, toPrint) => {
      setIsLoading(true);
      try {
        if (status === "F") {
          const orderData = await getOrderInfos(item.idOrcamento);

          const currentDate = moment().utc(-3).format("YYYY-MM-DD HH:mm:ss");

          const caixa = {
            items: [
              {
                origemValor: 0,
                vlValor: item.vlvalor,
                fluxoEfetivo: false,
                dtData: currentDate,
                cdEmpresa: item.cdEmpresa,
                cdTipoMovimentacao: 2,
                cdRecibo: item.idOrcamento,
                cdEvento: 0,
                cdSupriSangria: 0,
                cdDespesa: 0,
                isOs: false,
                cdReceita: 0,
                cdFuncionario: companies[0].cdPessoa,
                cdPessoa: item.cdPessoa,
                cdEntrada: 0,
                cdFormaPagamento: item.cdFormaPagamento,
                nrOrcamento: item.idOrcamento,
                cdCaixa: item.idOrcamento,
                idCanalVenda: 5,
                recebivel: false,
                tef: false,
              },
            ],
          };

          const pessoa = {
            rg: "",
            cpf_cnpj: item.cpf,
            apelido: "",
            codigo: 0,
            inscricaoMunicipal: "",
            razaoSocial: "",
            enderecos: {
              listHelper: [],
            },
            inscricaEstadual: "",
            fisico_juridico: "F",
            nome: item.nmPessoa,
            observacoes: "",
            tipoPessoa: "F",
            senha: "",
            cdPessoa: item.cdPessoa,
            email: item.dsEmail,
            ser: "CLIENTE",
            telefones: {
              listHelper: [],
            },
            cdMatriz: "",
            idClienteIfood: "",
            idTabPreco: -1,
            atacado: false,
            cdEmpresa: item.cdEmpresa,
            ativo: true,
            tipoSer: "C",
            fixarComanda: false,
            isInoveClube: true,
          };

          const items = orderData.products.map((element) => {
            const items = element.additionals.map((additional) => {
              return {
                cdObservacao: additional.idAdditional,
                qtdVenda: additional.quantity,
                vlPreco: additional.price,
              };
            });
            const observacoes = element.additionals.length === 0 ? null : { items };
            return {
              cdProduto: element.idProduct,
              tipoEmbalagem: {
                cdTipoEmbalagem: 0,
              },
              qtd: element.quantity,
              vlPreco: element.price,
              vlDescontoItem: element.discount,
              cdIteLcto: element.cdIteLcto,
              cancelado: false,
              garcom: null,
              cdUsuarioEstorno: 0,
              qtdEstorno: 0,
              dtEstorno: null,
              dtPedido: null,
              vlServicoReal: 0,
              obs: element.obs,
              observacoes,
              acompanhamentos: null,
            };
          });

          const pedido = {
            items: [
              {
                nrOrcamento: item.idOrcamento,
                cdEmpresa: item.cdEmpresa,
                cliente: { cdPessoa: item.cdPessoa },
                garcom: { cdPessoa: companies[0].cdPessoa },
                dtEmissao: currentDate,
                vlDesconto: orderData.discountPercent,
                vlDescontoLiquido: orderData.discount,
                vlLiquido: orderData.total,
                vlTaxaEntrega: orderData.feeDelivery,
                vlServico: 0,
                nrOrcamentoDia: item.nrOrcamentoDia,
                cdMesa: "", // -----------
                tipoPedido: item.tipoPedido,
                cdApartamento: "",
                vlOcupacao: 0,
                trocoPara: orderData.paymentMethod.changeValue,
                cancelado: false,
                cdUsuarioCancelou: 0,
                dtCancelamento: null,
                origemPedido: 5,
                vlServicoPorc: 0,
                vlItens: orderData.subtotal,
                cdTipoMovimentacao: 2,
                dsObsCancelamento: "",
                vlPagoAMais: 0,
                saida: false,
                dsImpresso: "S",
                idCanalVenda: 5,
                andamentoPedido: {
                  mensagemRejeicao: orderData.mensagemRejeicao,
                  dtConfirmacao: orderData.dtConfirmacao,
                  dtSaida: orderData.dtSaida,
                  cdEmpresa: item.cdEmpresa,
                  dtUltimaAlteracao: orderData.dtUltimaAlteracao,
                  dtChegada: orderData.dtUltimaAlteracao,
                  dtDataPedido: orderData.date,
                  hrSaida: "",
                  dsStatus: "F",
                  dtFinalizacao: orderData.dtFinalizacao,
                  hrChegada: "",
                  cdMotoboy: item.cdcMotoboy,
                  dsEntregaResidencia: orderData.dsEntregaResidencia,
                  nrOrcamento: item.idOrcamento,
                },
                listaProdutos: {
                  items,
                },
              },
            ],
          };
          await Promise.all([
            sincronizadorApi.post("/saveCaixa", {
              ...caixa,
            }),
            sincronizadorApi.post("/salvarPessoa", {
              ...pessoa,
            }),
            sincronizadorApi.post("/salvarPedido", {
              ...pedido,
            }),
          ]);
        }

        // if (toPrint === true) {
        //   await printOrder(item);
        // }

        if (status === "E") {
          // Show the Dialog to chose a motoboy
          setChoseMotoboy(true);
          setMotoboyDialogInfo({
            idOrcamento: item.idOrcamento,
            company: item.cdEmpresa,
          });
        }

        await api.post("/updateStatusPedido", {
          purchase: {
            status,
            idOrcamento: item.idOrcamento,
            inoveClube: true,
            cdPessoa: item.cdPessoa,
            cdEmpresa: item.cdEmpresa,
            isPgtoOnline: item.dtPgtoOnline !== null,
          },
        });
        await handleGetOrders(true);
      } catch (error) {
        console.log(error);
        toast.error("Falha ao atualizar pedido!");
      } finally {
        setIsLoading(false);
      }
    },
    [companies, handleGetOrders, item, setChoseMotoboy, setMotoboyDialogInfo],
  );

  const generateWhatsAppMessage = (item) => {
    switch (item.dsStatus) {
      case "A":
        return `Olá, ${item.nmPessoa}! Seu pedido de número ${item.idOrcamento} está em aberto.`;
      case "W":
        return `Olá, ${item.nmPessoa}! Seu pedido de número ${item.idOrcamento} está aguardando pagamento.`;
      case "C":
        return `Olá, ${item.nmPessoa}! Seu pedido de número ${item.idOrcamento} foi cancelado.`;
      case "R":
        return `Olá, ${item.nmPessoa}! Seu pedido de número ${item.idOrcamento} foi rejeitado.`;
      case "P":
        return `Olá, ${item.nmPessoa}! Seu pedido de número ${item.idOrcamento} foi aceito e está sendo preparado.`;
      case "E":
        return `Olá, ${item.nmPessoa}! Seu pedido de número ${item.idOrcamento} saiu para entrega.`;
      case "F":
        return `Olá, ${item.nmPessoa}! Seu pedido de número ${item.idOrcamento} foi finalizado.`;
      default:
        return `Olá, ${item.nmPessoa}! Sobre o seu pedido de número ${item.idOrcamento}...`;
    }
  };

  // ---- Effects
  useEffect(() => {
    if (item.dtAgendamento) return;
    if (item.dsStatus === "A" || item.dsStatus === "W") {
      let currentTime = localTimer ?? 30;
      const timer = setInterval(() => {
        setTimerToAutoAceptPurchase((prev) => prev - 1);
      }, 1000);

      return () => {
        localStorage.setItem(
          `@inove-clube:timer-${item.idOrcamento}`,
          JSON.stringify(currentTime),
        );
        clearInterval(timer);
      };
    } else {
      localStorage.removeItem(`@inove-clube:timer-${item.idOrcamento}`);
    }
  }, [item.dsStatus, item.idOrcamento, item.dtAgendamento, localTimer]);

  useEffect(() => {
    if (item.dtAgendamento) return;
    if (timerToAutoAceptPurchase === 0) {
      updateStatusPedido("P");
      setTimerToAutoAceptPurchase(-1);
    }
  }, [timerToAutoAceptPurchase, updateStatusPedido, item.dtAgendamento]);

  // Return
  return (
    <>
      <Container>
        {isRequestConfirmationOpen && (
          <RequestConfirmation>
            <div className="wrapper">
              {item.dsStatus === "A" || item.dsStatus === "W" ? (
                <span>Deseja cancelar o pedido?</span>
              ) : (
                <span>Deseja finalizar o pedido?</span>
              )}
              <div>
                <button
                  onClick={() => {
                    setIsRequestConfirmationOpen(false);
                    setIsLoading(false);
                  }}
                >
                  Não
                </button>
                <button
                  onClick={() => {
                    setIsRequestConfirmationOpen(false);
                    if (item.dsStatus === "A" || item.dsStatus === "W") {
                      updateStatusPedido("R", item);
                    } else if (item.dsStatus === "E") {
                      updateStatusPedido("F", item);
                    }
                  }}
                  autoFocus={true}
                >
                  Sim
                </button>
              </div>
            </div>
          </RequestConfirmation>
        )}
        <header>
          <div>
            <span>{orderTypeDescription(item.tipoPedido)}</span>
            <span>ID: {item.idOrcamento}</span>
          </div>
          <div>
            <span>{item.dsEmpresa}</span>
            {!isSchedule && (
              <span>
                Pedido feito às {moment(item.dtEmissao).utc().format("DD/MM/YYYY, HH:mm")}
              </span>
            )}
            {item.dtAgendamento && (
              <span className="schedule">
                Agendado para {moment(item.dtAgendamento).utc().format("DD/MM/YYYY, HH:mm")}
              </span>
            )}
          </div>
        </header>
        <div className={`wrapper ${isLoading === true ? "loading" : ""}`}>
          <div className="info-wrapper">
            <div className="title">
              <span>{item.nmPessoa}</span>
              <div>
                {item.telefone && (
                  <a
                    href={`https://api.whatsapp.com/send?phone=55${item.telefone.replace(
                      "-",
                      "",
                    )}&text=${generateWhatsAppMessage(item)}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaWhatsappSquare className="whats" />
                  </a>
                )}
                {!isSchedule && (
                  <>
                    {(item.dsStatus === "E" || item.dsStatus === "P") && (
                      <AiFillPrinter
                        size={26}
                        className="printer-icon"
                        onClick={() => {
                          if (!isLoading && item.dsStatus === "E") {
                            printOrder(item, setIsLoading);
                          } else if (!isLoading && item.dsStatus === "P") {
                            printOrder(item, setIsLoading);
                          }
                        }}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
            <span>{item.dsBairro}</span>
            <div className="toggle-wrapper">
              <span>{formatMoney(item.vlvalor)}</span>
              {isDetailsOpen === true || isPurchaseDetailLoading === true ? (
                <span className="toggle" onClick={showPurchaseDetails}>
                  Ver produtos <FaArrowUp />
                </span>
              ) : (
                <span className="toggle" onClick={showPurchaseDetails}>
                  Ver produtos <FaArrowDown />
                </span>
              )}
            </div>
            {isDetailsOpen ? (
              <div className="purchase-infos">
                {purchaseDetails.products &&
                  purchaseDetails.products.map((element, idx) => {
                    if (element.additionals.length !== 0) {
                      const additionalItens = element.additionals.map((additional, idx) => {
                        return (
                          <span className="additionals" key={idx}>
                            {additional.quantity} {additional.name}
                          </span>
                        );
                      });
                      return (
                        <div key={idx}>
                          <span>
                            {element.quantity} {element.name}
                          </span>
                          <span>{element.obs}</span>
                          {additionalItens}
                        </div>
                      );
                    } else {
                      return (
                        <div key={idx}>
                          <span>
                            {element.quantity} {element.name}
                          </span>
                          <span>{element.obs}</span>
                        </div>
                      );
                    }
                  })}
              </div>
            ) : isPurchaseDetailLoading ? (
              <div className="purchase-loading">
                <div></div>
                <div></div>
              </div>
            ) : null}

            <span>
              {item.consumirNaLoja ? "Pedido para consumir na loja" : "Pedido para retirada"}
            </span>

            {!isSchedule && (
              <div className="icons-wrapper">
                <span>
                  {item.tipoPgto === 0 ? (
                    <FaMoneyBillAlt />
                  ) : item.tipoPgto === 2 ? (
                    <BsCreditCard />
                  ) : item.tipoPgto === 9 ? (
                    <RiBankLine />
                  ) : (
                    <MdAttachMoney />
                  )}
                </span>
                <div className="action-btns">
                  <button
                    disabled={isLoading}
                    className={item.dsStatus}
                    onClick={() => {
                      setIsLoading(true);
                      if (item.dsStatus === "A" || item.dsStatus === "W") {
                        setIsRequestConfirmationOpen(true);
                      } else if (item.dsStatus === "P") {
                        updateStatusPedido("A");
                      } else if (item.dsStatus === "E") {
                        updateStatusPedido("P");
                      }
                    }}
                  >
                    {item.dsStatus === "A" || item.dsStatus === "W"
                      ? "Rejeitar"
                      : item.dsStatus === "P"
                        ? "voltar para Pendente"
                        : item.dsStatus === "E" && "Voltar para Preparando"}
                  </button>
                  <button
                    disabled={isLoading}
                    className={item.dsStatus}
                    onClick={() => {
                      setIsLoading(true);
                      if (item.dsStatus === "A" || item.dsStatus === "W") {
                        // clearInterval(timer);
                        updateStatusPedido("P", true);
                        localStorage.removeItem(`@inove-clube:timer-${item.idOrcamento}`);
                      } else if (item.dsStatus === "P") {
                        updateStatusPedido("E");
                      } else if (item.dsStatus === "E") {
                        setIsRequestConfirmationOpen(true);
                      }
                    }}
                  >
                    {item.dtAgendamento && (item.dsStatus === "A" || item.dsStatus === "W")
                      ? "Preparar"
                      : item.dsStatus === "A" || item.dsStatus === "W"
                        ? `Aceitar (${
                            timerToAutoAceptPurchase > 0 ? timerToAutoAceptPurchase : 0
                          })`
                        : item.dsStatus === "P"
                          ? "Entregar"
                          : item.dsStatus === "E" && "Finalizar"}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Container>
    </>
  );
};
