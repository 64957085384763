import { useEffect, useState } from "react";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import { FaMotorcycle, FaCartArrowDown, FaMoneyBillAlt } from "react-icons/fa";
import { formatMoney } from "../../../../Utils/formatMoney";
import { useAuth } from "../../../../context/AuthContext";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { api } from "../../../../services/api";
import { BiFoodMenu } from "react-icons/bi";
import { GiShoppingBag } from "react-icons/gi";
import { SiAirtable } from "react-icons/si";
import { MdAttachMoney } from "react-icons/md";
import { BsCreditCard } from "react-icons/bs";
import { RiBankLine } from "react-icons/ri";
import { GeneralLoader } from "../../../loaders/generalLoader";
import { ConfirmToShop } from "./confirmToShop";
import moment from "moment";
// import {saveLogClube} from '../../../../services/logClube';

import { Container, Content, Header, Informations, OrderDayCaontainer } from "./style";

export function ModalOrderDetails({
	linkImage,
	orderNumber,
	nameBusiness,
	setTableOpened,
	statusList,
	formatoMoeda
}) {
	const posErro = statusList.findIndex((item) => item.status === "ERROR");

	const [dataOrder, setDataOrder] = useState({});
	const [isLoading, setIsLoading] = useState(true);
	const [openConfirmToShop, setOpenConfirmToShop] = useState({
		open: false,
		inactiveProducts: [],
	});

	const history = useHistory();
	const { signOut } = useAuth();

	useEffect(() => {
		async function fetchData() {
			try {
				await api
					.get(
						"/getOrder/" + orderNumber + "/" + localStorage.getItem("@Inoveclube:token"),
						{
							headers: {
								token: localStorage.getItem("@Inoveclube:token"),
							},
						}
					)
					.then((response) => {
						setDataOrder(response.data);
					});
				setIsLoading(false);
			} catch (error) {
				console.log("🚀 ~ fetchData ~ error:", error)
				if (error.response.status === 401 || error.response.status === 500) {
					signOut();
					toast.warning("Sua sessão foi expirada");
				} else {
					toast.error(error.response.data.message);
					history.push("/profile");
				}
			}
		}
		fetchData();
	}, [orderNumber, signOut, history]);

	function handleRedirectTo() {
		window.location.href = `${process.env.REACT_APP_URL}menu/${dataOrder.nameBusiness}`;
	}

	function reorderActiveProducts() {
		const array = dataOrder.products.filter(
			(product) =>
				product.active &&
				product.additionals.every((additional) => !!additional.active) &&
				product.composition.every((comp) => !!comp.active)
		);

		return array;
	}

	function reorderInactiveProducts() {
		const array = dataOrder.products.filter(
			(product) =>
				!product.active ||
				product.additionals.some((additional) => !additional.active) ||
				product.composition.some((comp) => !comp.active)
		);

		return array;
	}

	async function assemblingReorder() {
		const activeProducts = reorderActiveProducts();

		const shoppingArray = activeProducts.map((product) => {
			let listAssemblyItensSelected = product.composition.map((comp) => {
				let obj = {
					idProduct: comp.idProduct,
					name: comp.name,
					price: comp.price,
					filled: true,
				};
				return obj;
			});

			let selectedOptions = product.additionals.map((additional) => {
				let obj = {
					cdOption: additional.idAdditional,
					obsDescription: additional.name,
					price: additional.currentPrice,
					qtd: additional.quantity,
				};
				return obj;
			});

			let object = {
				addCounterProduct: product.quantity,
				assembledItensFilled: product.composition.length,
				code: product.idProduct,
				cost:
					product.currentPrice +
					(selectedOptions.length !== 0
						? selectedOptions
								?.map((value) => value.price * value.qtd)
								.reduce((value, next) => value + next)
						: 0),
				listAssemblyItensSelected,
				name: product.name,
				selectedOptions,
				size: product.size.idPackSize,
				sizeDescription: product.size.description,
				src: product.src,
				textAdditional: product.obs,
				vlPagarmeService: 0.99,
			};

			return object;
		});

		localStorage.setItem(
			`@Inoveclube:listBuying${dataOrder.nameBusiness}`,
			JSON.stringify(shoppingArray)
		);

		localStorage.setItem("@Inoveclube:nameBusiness", dataOrder.nameBusiness);

		const { data: schedulingData } = await api.get(
			`/checkIfSchedulingTimesIsActive/${dataOrder.cdEmpresa}`
		);

		if (schedulingData.schedulingTimesActive) {
			localStorage.setItem("@Inoveclube:choseOrderType", true);
			history.push(`/menu/${dataOrder.nameBusiness}`);
			return;
		}

		history.push(`/shopping`);
	}

	function handleMakeReorder() {
		const inactiveProducts = reorderInactiveProducts();

		if (inactiveProducts.length === dataOrder.products.length) {
			toast.error("Essa compra não pode ser refeita");
			return;
		}

		if (inactiveProducts.length > 0) {
			setOpenConfirmToShop({ open: true, inactiveProducts });
			return;
		}

		assemblingReorder();
	}

	const status = statusList.filter(function (stt) {
		return stt.current === true;
	});
	const dateFormated = dataOrder.date?.slice(0, -1);
	const dateStatusFormated = status[0].date?.slice(0, -1);

	return (
		<Container>
			<Content>
				<Header linkImage={linkImage}>
					<div className="container-info">
						<div />
						<div className="businessDatas">
							<p>{nameBusiness}</p>
							<p>Nº {orderNumber}</p>
							{!isLoading ? (
								<p>
									{moment(new Date(dateFormated)).locale("pt-BR").format("HH:mm")}h -{" "}
									{moment(new Date(dateFormated)).format("DD/MM/YYYY")}
								</p>
							) : (
								<div />
							)}
						</div>
					</div>
					<span onClick={() => setTableOpened(false)}>&#10005;</span>
				</Header>
				<OrderDayCaontainer>
					{dataOrder.orcamentoDia && (
						<>
							<div className="header">
								<strong>Nº {dataOrder.orcamentoDia}</strong>

								<span>Aguarde ser chamado no painel</span>
							</div>

							<div className="divisor" />
						</>
					)}

					<div className="status">
						<div>
							{posErro > -1 ? (
								<AiFillCloseCircle color="#C22200" />
							) : (
								<AiFillCheckCircle />
							)}

							<p>
								{status[0].description}{" "}
								{dateStatusFormated &&
									`- ${moment(new Date(dateStatusFormated)).format("HH:mm")}`}
							</p>
						</div>
						{posErro > -1 ? <p className="errorMessage">{status[0].message}</p> : <></>}
					</div>
				</OrderDayCaontainer>

				<ConfirmToShop
					openConfirmToShop={openConfirmToShop}
					setOpenConfirmToShop={setOpenConfirmToShop}
					assemblingReorder={assemblingReorder}
				/>

				{isLoading === false ? (
					<>
						<div className="actionsOrderDetails">
							<div onClick={handleMakeReorder}>
								<FaCartArrowDown />
								<p>Pedir novamente</p>
							</div>

							<div onClick={handleRedirectTo}>
								<BiFoodMenu />
								<p>Ver cardápio</p>
							</div>
						</div>

						<div className="typeOfShopping">
							{dataOrder.typeShopping === "DELIVERY" ? (
								<FaMotorcycle />
							) : dataOrder.typeShopping === "TAKEAWAY" ? (
								<GiShoppingBag />
							) : dataOrder.typeShopping === "TABLE" ? (
								<SiAirtable />
							) : (
								<></>
							)}

							<p>{dataOrder.typeShoppingDescription}</p>
						</div>

						<Informations>
							<div className="productsContainer">
								{dataOrder.products.map((product) => (
									<div key={product.idProduct}>
										<div className="title">
											<p>
												<span>{product.quantity}</span> {product.name}{" "}
												{product.size?.description}
											</p>
											<p>{formatMoney(product.price * product.quantity, formatoMoeda)}</p>
										</div>
										{product.composition.map((obs) => (
											<p key={obs.idProduct} className="obsStyle">
												{obs.quantity} {obs.name}
											</p>
										))}
										{product.additionals.map((obs) => (
											<p key={obs.idAdditional} className="obsStyle">
												{obs.quantity}X {obs.name}
											</p>
										))}
										{product.obs !== "" ? (
											<p className="obsStyle">obs: {product.obs}</p>
										) : (
											<></>
										)}
									</div>
								))}
							</div>

							<div className="moneyInformations">
								<div className="containerMoneyInformation">
									<p>Subtotal</p>
									<p>{formatMoney(dataOrder.subtotal, formatoMoeda)}</p>
								</div>

								{dataOrder.typeShopping === "DELIVERY" ? (
									<div className="containerMoneyInformation">
										<p>Taxa de entrega</p>
										<span>
											{dataOrder.feeDelivery !== 0
												? formatMoney(dataOrder.feeDelivery, formatoMoeda)
												: "Grátis"}
										</span>
									</div>
								) : (
									<></>
								)}

								{
									// dataOrder.paymentMethod.description.toUpperCase().indexOf('ONLINE') > -1 &&
									// <div className="containerMoneyInformation">
									//     <p>Taxa de serviço</p>
									//     <span>
									//         {
									//             dataOrder.vlPagarmeService !== 0 ?
									//             formatMoney(dataOrder.vlPagarmeService)
									//             :
									//             'Grátis'
									//         }
									//     </span>
									// </div>
								}

								{dataOrder.discount > 0 ? (
									<div className="containerMoneyInformation">
										<p>Cupom</p>
										<span>- {formatMoney(dataOrder.discount, formatoMoeda)}</span>
									</div>
								) : (
									<></>
								)}

								<div className="containerMoneyInformation">
									<h3>Total</h3>
									<h3>{formatMoney(dataOrder.total, formatoMoeda)}</h3>
								</div>
							</div>

							{dataOrder.paymentMethod.idPayment !== undefined ? (
								<div className="paymentContainer">
									{dataOrder.paymentMethod.type === 3 ||
									dataOrder.paymentMethod.type === 2 ? (
										<BsCreditCard />
									) : dataOrder.paymentMethod.type === 0 ? (
										<FaMoneyBillAlt />
									) : dataOrder.paymentMethod.type === 9 ? (
										<RiBankLine />
									) : (
										<MdAttachMoney />
									)}
									<p>{dataOrder.paymentMethod.description}</p>
								</div>
							) : (
								<></>
							)}

							{dataOrder.typeShopping === "DELIVERY" ? (
								<div className="addressContainer">
									<p>Endereço de entrega</p>
									<p>
										{dataOrder.address.street}, {dataOrder.address.number}{" "}
										{dataOrder.address?.complement &&
										dataOrder.address.complement !== ""
											? `- ${dataOrder.address.complement}`
											: ""}
									</p>
									<p>
										{dataOrder.address.neighborhood} - {dataOrder.address.city} -{" "}
										{dataOrder.address.state}
									</p>
									{dataOrder.address?.reference &&
									dataOrder.address.reference !== "" ? (
										<div>{dataOrder.address.reference}</div>
									) : (
										<></>
									)}
								</div>
							) : (
								<></>
							)}
						</Informations>
					</>
				) : (
					<GeneralLoader />
				)}
			</Content>
		</Container>
	);
}
