import styled from "styled-components"


export const Container = styled.div`
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.7rem;
    border-radius: 0.25rem;
    border: 1px solid #7A05A7;
`;


export const Input = styled.input`
    width: 100%;
    background: none;
    color: #7A05A7;
    border: none;
    outline: none;
    font-weight: 500;
    font-size: 1rem;

    position: relative;
    /* flex: auto; */

    opacity: ${props => props.active ? 1 : 0.5};
    pointer-events: ${props => props.active ? 'auto' : 'none'};

    &::-webkit-calendar-picker-indicator {
        color: transparent;
        background: none;
        z-index: 1;
        
        @media (min-width: 960px){
            cursor: pointer;
        }
    }

    &:before {
        color: transparent;
        background: none;
        display: block;
        font-family: 'material icons';
        content: 'date_range';
        width: 1rem;
        position: absolute;
        top: 50%;
        right: 6px;
        color: #7A05A7;
        transform: translateY(-50%);
    }
`