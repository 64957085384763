import { Daily } from "./components/daily"
import { Weekly } from "./components/weekly"
import { Indeterminate } from "./components/indeterminate"

export function SchedulingModal({
	toClose,
	scheduling,
	setSchedulingTime,
	generateTimesInterval,
	setSchedulingDate,
	schedulingDate,
}) {
	const schedulingData = {
		toClose,
		scheduling,
		setSchedulingTime,
		generateTimesInterval,
		setSchedulingDate,
		schedulingDate,
	}
	// ==== Render ====
	return (
		<>
			{
				{
					daily: <Daily schedulingData={schedulingData} />,
					weekly: <Weekly schedulingData={schedulingData} />,
					indeterminate: <Indeterminate schedulingData={schedulingData} />,
				}[scheduling.type]
			}
		</>
	)
}
