import { useCallback, useEffect, useRef, useState } from "react";
import { useMenu } from "../../../context/MenuContext";
import { saveLogClube } from "../../../services/logClube";
import { formatMoney } from "../../../Utils/formatMoney";

import { ContainerList, CardContainer, CardList, Container } from "./style";

export function Card({
	products,
	title,
	image,
	searchTerm,
	searchResults,
	businessDatas,
}) {
	const [click, setClick] = useState(false);
	const [height, setHeight] = useState("0px");

	const nameBusiness = window.location.pathname.split("/")[2];

	const myRef = useRef(null);
	const heightRef = useRef(null);

	const {
		handleSetItem,
		handleSetImageModal,
		addAdditionalPriceToProduct,
		fetchAdditionalData,
	} = useMenu();

	const handleClick = useCallback(() => {
		setClick(!click);

		setHeight(click === false ? `${heightRef.current.scrollHeight}px` : "0px");
	}, [click]);

	const styleDefault = {
		backgroundImage: "linear-gradient(transparent 60%, rgba(0, 0, 0, 0.9) )",
	};

	useEffect(() => {
		const pixel = myRef.current?.offsetTop - 150;

		if (click === true) {
			setTimeout(() => {
				window.scrollTo({ behavior: "smooth", top: pixel });
			}, 200);
		}
	}, [click]);

	function MinValue(product) {
		const compositions = product.sizes
			? product.sizes
					.map((item) => {
						return item.composition;
					})
					.reduce((value, next) => {
						return [...value, ...next];
					}, [])
			: [];

		const prices = compositions.map((item) => {
			return Number(item.price);
		});

		return Math.min(...prices) === Infinity ? 0 : Math.min(...prices);
	}

	function getText(product) {
		const message =
			businessDatas.mensagemMontagemProduto || "Monte seu produto a partir de";

		if (product.isAssembledProduct)
			return `${message} ${formatMoney(
				MinValue(product),
				businessDatas.formatoMoeda
			)}`;
		if (product.minValue > 0)
			return `${message} ${formatMoney(
				product.price + product.minValue,
				businessDatas.formatoMoeda
			)}`;

		return formatMoney(product.price, businessDatas.formatoMoeda);
	}

	return (
		<>
			{searchTerm.length !== "" && searchTerm.trim() !== "" ? (
				searchResults.map((item) =>
					products.map((product, index) =>
						product.name === item ? (
							<ContainerList key={product.idProduct}>
								<CardList key={index} productImage={product.linkImage}>
									{product.linkImage !== "" ? (
										<div
											className="image"
											onClick={() => {
												handleSetImageModal({
													show: true,
													src: product.linkImage,
												});
											}}
										></div>
									) : (
										<></>
									)}
									<div
										className="text"
										onClick={() => {
											handleSetItem({
												code: product.idProduct,
												src: product.linkImage,
												name: product.name,
												cost: product.price,
												enable: product.enabled,
												assembled: product.isAssembledProduct,
												sizes: product.sizes,
												businessDatas,
												show: true,
												promotionValue: product.promotionValue,
											});
											fetchAdditionalData(
												product.idProduct,
												nameBusiness
											);
											addAdditionalPriceToProduct(product.price);
											saveLogClube({
												nameBusiness: localStorage.getItem(
													"@Inoveclube:nameBusiness"
												),
												idStatus: 2,
												token: localStorage.getItem(
													"@Inoveclube:token"
												),
												idProduct: product.idProduct,
												isTakeaway:
													localStorage.getItem(
														"@Inoveclube:type"
													) === "takeaway",
												isTable:
													localStorage.getItem(
														"@Inoveclube:type"
													) === "table",
											});
										}}
									>
										<h3>{item}</h3>
										<h4>{product.additionalInformation}</h4>
										{product.oldPrice > 0 ? (
											<span className="last-price">
												{formatMoney(
													product.oldPrice,
													businessDatas.formatoMoeda
												)}
											</span>
										) : (
											<></>
										)}
										<span>{getText(product)}</span>
									</div>
								</CardList>
							</ContainerList>
						) : (
							<></>
						)
					)
				)
			) : (
				<>
					<CardContainer
						onClick={() => handleClick(myRef)}
						ref={myRef}
						image={image}
						style={image === "" ? styleDefault : { color: "white" }}
					>
						<h2>{title}</h2>
					</CardContainer>

					<Container height={height} ref={heightRef}>
						{products.map((product, index) => (
							<ContainerList key={product.idProduct}>
								<CardList
									key={index}
									productImage={product.linkImage}
									click={click}
								>
									{product.linkImage !== "" ? (
										<div
											className="image"
											onClick={() => {
												handleSetImageModal({
													show: true,
													src: product.linkImage,
												});
											}}
										></div>
									) : (
										<></>
									)}
									<div
										className="text"
										onClick={() => {
											handleSetItem({
												code: product.idProduct,
												src: product.linkImage,
												name: product.name,
												cost: product.price,
												enable: product.enabled,
												assembled: product.isAssembledProduct,
												sizes: product.sizes,
												businessDatas,
												show: true,
												promotionValue: product.promotionValue,
											});
											fetchAdditionalData(
												product.idProduct,
												nameBusiness
											);
											addAdditionalPriceToProduct(product.price);
											saveLogClube({
												nameBusiness: localStorage.getItem(
													"@Inoveclube:nameBusiness"
												),
												idStatus: 2,
												token: localStorage.getItem(
													"@Inoveclube:token"
												),
												idProduct: product.idProduct,
												isTakeaway:
													localStorage.getItem(
														"@Inoveclube:type"
													) === "takeaway",
												isTable:
													localStorage.getItem(
														"@Inoveclube:type"
													) === "table",
											});
										}}
									>
										<h3>{product.name}</h3>
										<h4>{product.additionalInformation}</h4>
										{product.oldPrice > 0 ? (
											<span className="last-price">
												{formatMoney(
													product.oldPrice,
													businessDatas.formatoMoeda
												)}
											</span>
										) : (
											<></>
										)}
										<span>{getText(product)}</span>
									</div>
								</CardList>
							</ContainerList>
						))}
					</Container>
				</>
			)}
		</>
	);
}
