import { useEffect, useState } from 'react'
import { IoIosBusiness } from 'react-icons/io'
import { FiLock, FiPhone } from 'react-icons/fi'
import { InputGeneric } from '../../../components/Forms/InputGeneric'
import { Button } from '../../../components/Button'
import { api } from '../../../services/api'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router'

import { Container, Logo } from './style';


export function WholeSaleRegister({ params }) {
    const [cnpj, setCnpj] = useState(params.cnpj)
    const [password, setPassword] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const history = useHistory()
    

    useEffect(() => {
        document.getElementById("favicon").href = "https://inovesystem.s3.sa-east-1.amazonaws.com/favicon.ico"
    }, [])


    async function savePerson(obj) {
        let object = {
            cpf: cnpj,
            password,
            phoneNumber,
            name: obj.name,
            email: obj.email,
            idTerm: 1,
            birthDate: '',
            nameBusiness: localStorage.getItem('@Inoveclube:nameBusiness') !== undefined  
                ? localStorage.getItem('@Inoveclube:nameBusiness') 
                : ''
        }

        try{
            const response = await api.post('/savePerson', object, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })

            const { success, token } = response.data

            if(success === 1) {
                localStorage.setItem('@Inoveclube:token', token)
                localStorage.setItem('@Inoveclube:userName', obj.name)
                localStorage.setItem('@Inoveclube:checked', true)
                history.push(`/menu/${params.business}`)
            }else
                throw new Error()

        }catch{
            toast.error('Ocorreu um erro, tente novamente')
        }


    }


    async function handleRegisterWholesale() {
        try{
            if(cnpj.trim() === '' || phoneNumber.trim() === '' || password.trim() === ''){
                toast.error('Preencha todos os dados')
                return
            }

            if(password !== confirmPassword){
                toast.error('As senhas não coincidem')
                return
            }

            const response = await api.get(`/getPersonWholesale/${cnpj}/${params.business}`)

            savePerson(response.data)

        }catch{
            toast.error('Erro ao buscar dados. Verifique com a matriz')
        }
    }


    return(
        <Container>
            <Logo />

            <InputGeneric 
                type="text"
                icon={<IoIosBusiness/>}
                placeholder="CNPJ ou CPF"
                maxLength={14}
                value={cnpj}
                color={'#7A05A7'}
                onChange={event => setCnpj(event.target.value)}
            />

            <InputGeneric 
                type="text"
                icon={<FiPhone/>}
                placeholder="Telefone"
                mask="(99)999999999"
                value={phoneNumber}
                color={'#7A05A7'}
                onChange={event => setPhoneNumber(event.target.value)}
            />

            <InputGeneric 
                type="password"
                icon={<FiLock/>}
                placeholder="Senha"
                maxLength={10}
                value={password}
                color={'#7A05A7'}
                visibility={true}
                onChange={event => setPassword(event.target.value)}
            />

            <InputGeneric 
                type="password"
                icon={<FiLock/>}
                placeholder="Confirme sua senha"
                maxLength={10}
                value={confirmPassword}
                color={'#7A05A7'}
                onChange={event => setConfirmPassword(event.target.value)}
            />

            <Button 
                onClick={handleRegisterWholesale}
                background={'#7A05A7'}
                color={'#FFF'}
            >
                Cadastrar
            </Button>
            
        </Container>
    )
}
