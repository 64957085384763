import { GiCheckMark } from "react-icons/gi"

import { Container } from "./style"

export function InputCheck({ checked, handleClick, ...rest }) {
	return (
		<Container onClick={handleClick} {...rest}>
			{checked && <GiCheckMark />}
		</Container>
	)
}
