import { Button } from '../../../../components/Button'
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from 'react-icons/ai';
import { useEffect, useState } from 'react';

import { Container, Content } from './style'


export function EditProductQuantity({ 
    openEdit, 
    handleCloseEditProduct, 
    confirmEditProduct 
}) {
    const [quantity, setQuantity] = useState(1)

    function minusQtt() {
        if(quantity > 1)
            setQuantity(quantity - 1)
    }

    function plusQtt() {
        setQuantity(quantity + 1)
    }

    useEffect(() => {
        if(openEdit.open)
            setQuantity(openEdit.qtt)
    }, [openEdit])

    return(
        <Container open={openEdit.open}>
            <Content>
                <span onClick={handleCloseEditProduct}>&#10006;</span>
                <h4>{openEdit.name}</h4>

                <div className="buttons">
                    <div>
                        <AiOutlineMinusCircle onClick={minusQtt}/>
                        {quantity}
                        <AiOutlinePlusCircle onClick={plusQtt}/>
                    </div>
                    <Button
                        background="var(--colorButton)"
                        color="var(--colorLabel)"
                        onClick={() => confirmEditProduct(openEdit.index, quantity)}
                    >
                        Atualizar
                    </Button>
                </div>
            </Content>
        </Container>
    )
}
