// Imports
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
// Api
import { api } from "../../../services/api";
// Components
import { ModalToFilterUserLocation } from "../../../components/pagesComponents/mainPage/modalToFilterUserLocation";
import { SectorsAndBusiness } from "../../../components/pagesComponents/mainPage/SectorsAndBusiness";
import { Header } from "../../../components/pagesComponents/mainPage/header";
import { MainLoader } from "../../../components/loaders/mainLoader";
// Images
import woman from "../../../components/images/woman.png";
import phone from "../../../components/images/venda-online.png";
import partnership from "../../../components/images/partnership.svg";
import cupons from "../../../components/images/1-cupons.png";
import sorteios from "../../../components/images/2-sorteios.png";
import fidelidade from "../../../components/images/3-fidelidade.png";
import logo from "../../../components/images/logoPurple.png";
// Styles
import { Container, Content, Slide } from "./style";
// Swiper
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import SwiperCore, { Autoplay, EffectFade, Pagination } from "swiper";
import { PartnershipContactModal } from "../../../components/pagesComponents/mainPage/partnershipContactModal";
import { ShopkeeperContactModal } from "../../../components/pagesComponents/mainPage/shopkeeperContactModal";

SwiperCore.use([Autoplay, EffectFade, Pagination]);

export function MainPage() {
  // Estado de carregamento
  const [isLoading, setIsLoading] = useState(false);
  // Todos os setores disponíveis
  const [sectors, setSectors] = useState([]);
  // Setor selecionado
  const [sectorSelected, setSectorSelected] = useState(0);
  // Todas as empresas do setor selecionado
  const [business, setBusiness] = useState([]);
  // Próxima página de empresas
  const [nextPage, setNextPage] = useState(null);
  // Carregando mais empresas
  const [loadingMoreBusiness, setLoadingMoreBusiness] = useState(false);
  // Modal para filtrar localização do usuário
  const [modalToFilterUserLocationOpen, setModalToFilterUserLocationOpen] = useState(
    localStorage.getItem("@Inoveclube:location") ? false : true,
  );
  // Modal para contato de parceiro
  const [isPartnershipContactModalOpen, setIsPartnershipContactModalOpen] = useState(false);
  // Modal para contato de lojista
  const [isShopkeeperContactModalOpen, setIsShopkeeperContactModalOpen] = useState(false);
  // Localização atual do usuário
  let currentLocation = JSON.parse(localStorage.getItem("@Inoveclube:location"))?.idCity;
  const [hasFoodParks, setHasFoodParks] = useState(false);

  // ---- Funções
  // Função para carregar mais empresas
  async function fetchMoreBusiness() {
    setLoadingMoreBusiness(true);
    try {
      await api
        .get(`/getAllBusiness/${currentLocation}/${sectorSelected}/${nextPage}`)
        .then((response) => {
          setBusiness([...business, ...response.data.business]);
          setNextPage(response.data.next_page);
        });

      setLoadingMoreBusiness(false);
    } catch {
      setLoadingMoreBusiness(false);
      toast.error("A busca falhou, tente novamente");
    }
  }

  // Função para buscar empresas com filtros de setor, localização e filtros selecionados
  async function fetchBusinessDatas(idSector, filtersSelected) {
    const location = JSON.parse(localStorage.getItem("@Inoveclube:location")).idCity;
    const filters = filtersSelected || 0;
    try {
      await api
        .get(`/getAllBusiness/${location}/${idSector}/1`, {
          params: filters,
        })
        .then((response) => {
          setBusiness(response.data.business);
          setNextPage(response.data.next_page);
        });
      setSectorSelected(idSector);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("A busca falhou, tente novamente.");
    }
  }

  const fetchFoodParksDatas = async (idSector) => {
    try {
      await api.get(`/getAllFoodParks/${currentLocation}/${idSector}/1`).then((response) => {
        setBusiness(response.data.business);
        setNextPage(response.data.next_page);
      });
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("A busca falhou, tente novamente.");
    }
  };

  // Função para buscar setores
  async function fetchSectors(location, reload) {
    if (!reload) {
      if (location === currentLocation) {
        setModalToFilterUserLocationOpen(false);
        return;
      }
      setIsLoading(true);
    }
    try {
      await api.get(`/getSectors/${location}`).then((response) => {
        setSectors(response.data);
        if (response.data.length > 0) fetchBusinessDatas(response.data[0].idSector);
        else setIsLoading(false);
      });
      setModalToFilterUserLocationOpen(false);
    } catch {
      toast.error("A busca falhou, tente novamente");
    }
  }

  const fetchFoodParks = async () => {
    try {
      await api.get(`/getFoodParks/`).then((response) => {
        setSectors(response.data);
        setSectorSelected("foodpark");
        if (response.data.length > 0) {
          fetchFoodParksDatas(response.data[0].idSector);
        }
      });
      setModalToFilterUserLocationOpen(false);
    } catch {
      toast.error("A busca falhou, tente novamente");
    }
  };

  // ---- UseEffects
  useEffect(() => {
    (async () => {
      try {
        const response = await api.get(`/getFoodParks`);
        // console.log("🚀 ~ file: index.jsx:151 ~ response:", response)
        if (response.data.length > 0) {
          setHasFoodParks(true);
        }
      } catch (error) {
        console.log(error);
        toast.error("A busca falhou, tente novamente.");
      }
    })();
  }, []);

  // Buscar setores ao carregar a página
  useEffect(() => {
    async function refetchSectors() {
      try {
        await api.get(`/getSectors/${currentLocation}`).then((response) => {
          setSectors(response.data);
          if (response.data.length > 0) fetchBusinessDatas(-1);
          else setIsLoading(false);
        });
        setModalToFilterUserLocationOpen(false);
      } catch {
        toast.error("A busca falhou, tente novamente");
      }
    }
    if (currentLocation) refetchSectors();
  }, [currentLocation]);

  // Buscar localização do usuário ao carregar a página
  useEffect(() => {
    const location = JSON.parse(localStorage.getItem("@Inoveclube:location"));
    const token = localStorage.getItem("@Inoveclube:token");
    async function getLatlngUser() {
      try {
        const response = await api.get(`/getLatlngUser/${token}`);
        const cityDatasLocalStorage = response.data;
        localStorage.setItem(
          "@Inoveclube:location",
          JSON.stringify({
            description: cityDatasLocalStorage.dsMunicipio,
            idCity: cityDatasLocalStorage.cdMunicipio,
            latlgn: {
              lat: cityDatasLocalStorage.latitude,
              lng: cityDatasLocalStorage.longitude,
            },
            neighborhood: cityDatasLocalStorage.dsBairro,
          }),
        );
        if (cityDatasLocalStorage.latitude) {
          setModalToFilterUserLocationOpen(false);
        }
      } catch {
        toast.error("Localização não encontrada");
      }
    }

    if (!location && token) {
      getLatlngUser();
    }
  }, []);

  // Remover tipo de usuário do localStorage
  useEffect(() => {
    document.getElementById("favicon").href =
      "https://inovesystem.s3.sa-east-1.amazonaws.com/favicon.ico";
    localStorage.removeItem("@Inoveclube:type");
    localStorage.removeItem("@Inoveclube:typeNumber");
  }, []);

  // Retorno
  if (isLoading) return <MainLoader title="Buscando empresas..." />;
  return (
    <Container>
      {modalToFilterUserLocationOpen ? (
        <ModalToFilterUserLocation
          fetchSectors={fetchSectors}
          setModalToFilterUserLocationOpen={setModalToFilterUserLocationOpen}
        />
      ) : (
        <>
          {/* Partnership Contact Modal */}
          <PartnershipContactModal
            isPartnershipContactModalOpen={isPartnershipContactModalOpen}
            setIsPartnershipContactModalOpen={setIsPartnershipContactModalOpen}
          />
          {/* Shopkeeper Contact Modal */}
          <ShopkeeperContactModal
            isShopkeeperContactModalOpen={isShopkeeperContactModalOpen}
            setIsShopkeeperContactModalOpen={setIsShopkeeperContactModalOpen}
          />

          <Header setModalToFilterUserLocationOpen={setModalToFilterUserLocationOpen} />
          <section>
            <Swiper
              slidesPerView={1}
              className="swiper-container"
              autoplay={{
                delay: 5000,
                disableOnInteraction: true,
              }}
              effect="fade"
              loop={true}
              pagination={{
                clickable: true,
              }}
            >
              <SwiperSlide>
                <Slide>
                  <div className="container purple">
                    <div className="wrapper">
                      <h1>A revolução do seu clube de compras e benefícios</h1>
                      <p>Aqui você encontra preços baixos e cupons de desconto</p>
                    </div>
                    <img src={woman} alt="Mulher com megafone" className="illustration" />
                  </div>
                </Slide>
              </SwiperSlide>
              <SwiperSlide>
                <Slide
                  onClick={() => {
                    setIsShopkeeperContactModalOpen(true);
                  }}
                >
                  <div className="container orange action">
                    <div className="wrapper">
                      <h1>Cadastre a sua loja no iNove Clube e venda seus produtos online</h1>
                      <p>Analize os ganhos, gerencie pedidos e muito mais!</p>
                    </div>
                    <img
                      src={phone}
                      alt="Celular com compras"
                      className="illustration phone"
                    />
                  </div>
                </Slide>
              </SwiperSlide>
              <SwiperSlide>
                <Slide
                  onClick={() => {
                    setIsPartnershipContactModalOpen(true);
                  }}
                >
                  <div className="container green action">
                    <div className="wrapper">
                      <h1>Seja parceiro do iNove Clube e ganhe benefícios</h1>
                    </div>
                    <img
                      src={partnership}
                      alt="Celular com compras"
                      className="illustration phone"
                    />
                  </div>
                </Slide>
              </SwiperSlide>
            </Swiper>
          </section>
          <section className="cards-wrapper">
            <img src={cupons} alt="cupons" className="card" />
            <img src={sorteios} alt="sorteios" className="card" />
            <img src={fidelidade} alt="fidelidade" className="card" />
          </section>

          <section>
            <Content>
              <SectorsAndBusiness
                sectors={sectors}
                business={business}
                fetchBusinessDatas={fetchBusinessDatas}
                fetchMoreBusiness={fetchMoreBusiness}
                loadingMoreBusiness={loadingMoreBusiness}
                nextPage={nextPage}
                sectorSelected={sectorSelected}
                fetchFoodParks={fetchFoodParks}
                fetchFoodParksDatas={fetchFoodParksDatas}
                fetchSectors={fetchSectors}
                hasFoodParks={hasFoodParks}
              />
            </Content>
          </section>

          <footer>
            <div>
              <img src={logo} alt="Logo iNove System" />
              <span>Todos os direitos reservados</span>
            </div>
          </footer>
        </>
      )}
    </Container>
  );
}
