import { FaArrowLeft, FaCogs } from "react-icons/fa";
import { TbReload } from "react-icons/tb";
import { AiFillSound } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { PiStorefrontBold } from "react-icons/pi";
import { FaCashRegister } from "react-icons/fa6";
import { Container } from "./style";
import { useOrderManagerContext } from "../../context";

export function TopBar() {
  // ---- Hooks
  const history = useHistory();
  const {
    ordersIsLoading,
    orders,
    scheduledOrdersAmount,
    notify,
    handleGetOrders,
    setShowOpenCashRegister,
    setShowCloseCashRegister,
    orderIsPrinting,
    setShowSchedulingModal,
    setShowManageStoresModal,
  } = useOrderManagerContext();

  // ---- Variables
  const notDelivered =
    orders.length === 0
      ? 0
      : orders.filter(
          (order) => order.dsStatus === "A" || order.dsStatus === "P" || order.dsStatus === "E"
        ).length;
  const concluded =
    orders.length === 0 ? 0 : orders.filter((order) => order.dsStatus === "F").length;

  // ---- Return
  if (orderIsPrinting) return null;
  return (
    <Container>
      <div className="wrapper">
        <div className="logo-wrapper">
          <FaArrowLeft
            className="icon"
            size={26}
            onClick={() => {
              history.push("/adm");
            }}
          />
          <h1>Gestor de pedidos</h1>
        </div>
        <div className="info-wrapper">
          <div className="card-container">
            <span>{ordersIsLoading ? "..." : notDelivered}</span>
            <span>Não entregues</span>
          </div>
          <div className="card-container">
            <span>{ordersIsLoading ? "..." : concluded}</span>
            <span>Concluídos</span>
          </div>
          <div className="card-container" onClick={() => setShowSchedulingModal(true)}>
            <span>{ordersIsLoading ? "..." : scheduledOrdersAmount}</span>
            <span>Pedidos agendados</span>
          </div>
          <div className="card-container" onClick={handleGetOrders}>
            <TbReload />
            <span>Atualizar</span>
          </div>
          <div className="card-container" onClick={notify}>
            <AiFillSound />
            <span>Testar som</span>
          </div>
          <div className="card-container" onClick={() => setShowManageStoresModal(true)}>
            <PiStorefrontBold />
            <span>Abrir lojas</span>
          </div>
          <div className="card-container" onClick={() => setShowOpenCashRegister(true)}>
            <FaCogs className="icon" />
            <span>Gerenciar Lojas</span>
          </div>
          <div className="card-container" onClick={() => setShowCloseCashRegister(true)}>
            <FaCashRegister className="icon" />
            <span>Fechar Caixa</span>
          </div>
        </div>
      </div>
    </Container>
  );
}
