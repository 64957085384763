import { useState } from 'react'
import InputMask from 'react-input-mask'
import { MdVisibility, MdVisibilityOff } from 'react-icons/md'

import { Container } from './style'


const onlyNumbers = (str) => str.replace(/[^0-9]/g, '')


export function InputGeneric({ 
    mask = null, 
    filterNumbers = true,
    visibility = false,
    color = 'var(--mainTheme)',
    icon,
    type,
    onChange, 
    ...rest 
}) {
    const [visible, setVisible] = useState(false)

    function handleChange(event) {
        onChange({
            ...event,
            target: {
                ...event.target,
                value: onlyNumbers(event.target.value),
            }
        })
    }

    return(
        <Container 
            iconNotExists={icon === undefined}
            color={color}
        >
            {icon}
            <InputMask 
                type={visible ? 'text' : type}
                mask={mask}
                onChange={filterNumbers ? handleChange : onChange}
                {...rest}
            />
            
            { visibility && 
                <div onClick={() => setVisible(!visible)}>
                    {visible ? <MdVisibilityOff/> : <MdVisibility/>}
                </div>
            }
        </Container>
    )
}
