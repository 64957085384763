import moment from "moment";
import { SmallModal } from "../../../../../../components/smallModal";
import { Advice, List } from "./style";

export function Daily({ schedulingData }) {
	const timesToday = schedulingData.scheduling.schedulingTimes.filter(
		(time) =>
			time.weekDay ===
			moment().utcOffset(0).format("LLLL").split(",")[0].toLocaleLowerCase()
	);
	const avaliableTimes = schedulingData.generateTimesInterval({
		schedules: timesToday,
	});

	function handleChoseTime({ time }) {
		schedulingData.setSchedulingTime(time);
		schedulingData.toClose();
	}

	return (
		<SmallModal title="Agendamento de pedido" toClose={schedulingData.toClose}>
			<Advice>
				Loja fechada no momento <br />
				Selecione um dia para agendar seu pedido
			</Advice>
			<List>
				{avaliableTimes.map((time) => {
					if (time <= moment().format("HH:mm")) {
						return (
							<li key={time} className="expired">
								{time}
								<button>expirado</button>
							</li>
						);
					}
					return (
						<li key={time} onClick={() => handleChoseTime({ time })}>
							{time}
							<button>Selecionar</button>
						</li>
					);
				})}
			</List>
		</SmallModal>
	);
}
