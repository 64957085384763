import styled from 'styled-components'

export const Container = styled.div`
    min-width: ${props => props.size ? `${props.size}rem` : '2rem'};
    min-height: ${props => props.size ? `${props.size}rem` : '2rem'};
    margin-right: 1rem;

    border: 2px solid gray;
    border-radius: 5px;

    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;

    svg{
        position: absolute;
        top: -0.5rem;
        right: 0;
        left: 0;
        font-size: ${props => props.size ? `${props.size * 1.2}rem` : '2.2rem'};
        color: #7A05A7;
    }

    @media (min-width: 960px){
        cursor: pointer;
    }
`