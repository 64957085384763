import { useState, useEffect } from 'react';
import { ModalOrderDetails } from './modalOrderDetails'
import { RiCheckboxBlankCircleLine } from 'react-icons/ri'
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai'
import { MdWatchLater } from 'react-icons/md'
import { BsInfoCircle } from 'react-icons/bs'
import moment from 'moment'

import { Container } from './style';



export function Order({ nameBusiness, linkImage, statusList, orderNumber, minTimeDelivery, maxTimeDelivery, noLine, handleClickCancel, orderNumberDay, openModalPix, orderParams, formatoMoeda}) {

    const [tableOpened, setTableOpened] = useState(false);

    useEffect(() =>{
        tableOpened ? document.body.style.overflow = 'hidden' 
        : document.body.style.overflow = 'auto'
    }, [tableOpened]);

    const posErro = statusList.findIndex((item) => item.status === 'ERROR');
    
    return(
        <Container 
            tableOpened={tableOpened} 
            linkImage={linkImage}
            noLine={noLine}
        >
            <BsInfoCircle 
                className="info"
                onClick={async () => {
                    setTableOpened(!tableOpened)
                }}
            />

            <div className="container">
                <div className="container-info" 
                    onClick={async () => {
                        setTableOpened(!tableOpened)
                    }}
                >
                    <div></div>
                    <div className="businessDatas">
                        <p>{nameBusiness}</p>
                        <p>Nº {orderNumber}</p>
                    </div>
                </div>

                {
                    (orderNumberDay && orderParams?.orderType === 'B') &&
                    <div className='orderNumberDay'>
                        <p>
                            <strong>N° {orderNumberDay}</strong>
                            <span>Aguarde ser chamado no painel</span>
                        </p>
                    </div>
                }
               
                <div className="timeDelivery">
                    <MdWatchLater />
                    <p>{minTimeDelivery}-{maxTimeDelivery}min</p>
                </div>

                {
                    (
                        orderParams.qrCodeUrl && 
                        orderParams.qrCodeText && 
                        (orderParams.status !== 'Finished' && orderParams.status !== 'Cancelled')
                    ) && 
                    <div className="button-viewPix" onClick={() => openModalPix(orderParams)}>
                        <button>Visualizar Pix</button>
                    </div>
                }

                <div className="container-status"
                    onClick={async () => {
                        setTableOpened(!tableOpened)
                    }}
                >
                    <div className="container-balls">

                        {
                            statusList.map((stt, index) => {
                                const nextIsEnd = statusList[index+1] && statusList[index+1].current && (statusList[index+1].description === "Pedido finalizado") ? true : false;
                                return (
        
                                    stt.status === "OK" || nextIsEnd ?
                                        <div key={index} className="status">
                                            <div>
                                                <AiFillCheckCircle className="ball confirmed"/>
                                                <p className="status-title">{stt.description}</p>
                                                {stt.date !== ''?
                                                    <p className="status-date">{moment(stt.date?.slice(0, -1)).format("DD/MM/YYYY HH:mm")}</p>
                                                    : <></>
                                                }
                                            </div>
                                            {index+1 < statusList.length ?
                                                statusList[index+1].status !== "ERROR"?
                                                    <div className="progress"></div>
                                                    : 
                                                    <div className="stoped"></div>
                                            : <></>
                                            }
                                        </div>
                                    :
                                    stt.status === "PROGRESS" ?
                                        <div key={index} className="status">
                                            <div>
                                                <AiFillCheckCircle className="ball confirmed" />
                                                <p className="status-title">{stt.description}</p>
                                                {stt.date !== ''?
                                                    <p className="status-date">{moment(stt.date?.slice(0, -1)).format("DD/MM/YYYY HH:mm")}</p>
                                                    : <></>
                                                }
                                            </div>
                                            {index+1 < statusList.length ?
                                                <div className="animated-div"></div>
                                            : <></>
                                            }
                                        </div>
                                    :
                                    stt.status === "WAIT" ?
                                        <div key={index} className="status">
                                            <div>
                                                <RiCheckboxBlankCircleLine className={`ball ${statusList[index - 1].status === 'PROGRESS'? 'animated' : ''}`}/>
                                                <p className="status-title">{stt.description}</p>
                                                {stt.date !== ''?
                                                    <p className="status-date">{moment(stt.date?.slice(0, -1)).format("DD/MM/YYYY HH:mm")}</p>
                                                    : <></>
                                                }
                                            </div>
                                            {index+1 < statusList.length ?
                                                <div className="waiting"></div>
                                            : <></>
                                            }
                                        </div>
                                    :
                                    stt.status === "ERROR" ?
                                        <div key={index} className="status">
                                            <div>
                                                <AiFillCloseCircle className="ball refused"/>
                                                <p className="status-title-refused">{stt.description}</p>
                                                {stt.date !== ''?
                                                    <p className="status-date">{moment(stt.date?.slice(0, -1)).format("DD/MM/YYYY HH:mm")}</p>
                                                    : <></>
                                                }
                                            </div>
                                            {index+1 < statusList.length ?
                                                <div className="waiting"></div>
                                            : <></>
                                            }
                                        </div>
                                    : <></>
                                    
                                   
                                )
                            })
                        }

                    </div> 
                </div>
            </div>

                {
                //    statusList[0].current === true && 
                //     <div className="button-container">
                //         <button onClick={handleClickCancel}> Cancelar pedido </button>
                //     </div>
                }



                {
                    (statusList[posErro] && statusList[posErro].message) ?
                    <p className="reasonToCancel">
                        Mensagem: {statusList[posErro].message}
                    </p>
                    :
                    <></>
                }
            {tableOpened ? 
            <ModalOrderDetails 
                linkImage={linkImage} 
                orderNumber={orderNumber} 
                nameBusiness={nameBusiness}
                setTableOpened={setTableOpened}
                statusList={statusList}
                formatoMoeda={formatoMoeda}
            /> : <></>}
            
        </Container>
    )
}