import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"

import {
	Container,
	ContentLastUpdate,
	ContentHistory,
	Header,
	OrdersNotFound,
	FiltersContainer,
} from "./style"
import { AiOutlineArrowLeft } from "react-icons/ai"
import { FaAngleDown } from "react-icons/fa"
import { useOrder } from "../../services/hooks/useOrder"
import { toast } from "react-toastify"

import { Order } from "../../components/pagesComponents/order"
import { GeneralLoader } from "../../components/loaders/generalLoader"
import { ModalQrCodePix } from "./ModalQrCodePix"
import { Filters } from "./Filters"

import { api } from "../../services/api"

const typeStatusOrders = [
	{ type: "'A'", value: "Abertos" },
	{ type: "'W'", value: "Pagamento Online" },
	{ type: "'C'", value: "Cancelados" },
	{ type: "'R'", value: "Rejeitado" },
	{ type: "'P'", value: "Em Produção" },
	{ type: "'E'", value: "Saiu para Entrega" },
	{ type: "'F'", value: "Finalizado" },
]

export function Orders() {
	const [statusSelected, setStatusSelected] = useState(
		typeStatusOrders.map((item) => item.type)
	)

	const { data, isLoading } = useOrder(statusSelected)

	const lastOrder = data?.lastOrder
	const genericDatas = data?.genericDatas

	const [orderPix, setOrderPix] = useState(null)
	const [openModalFilters, setOpenModalFilters] = useState(false)

	useEffect(() => {
		if (lastOrder && !isLoading) {
			const hasPixInformation = lastOrder.qrCodeUrl && lastOrder.qrCodeText

			if (hasPixInformation && lastOrder.status === "Waiting Payment") {
				setOrderPix(lastOrder)
			}
		}

		return () => {
			setOrderPix(null)
		}
	}, [lastOrder, isLoading])

	async function handleClickCancel(order) {
		if (order.statusList[0].current !== true) {
			toast.error("Impossível de ser cancelado !!")
			return
		}

		await api
			.post("/updateStatusOrder", {
				dsStatus: "R",
				idOrcamentos: [order.orderNumber],
				mensagemRejeicao: "Pedido cancelado pelo cliente",
				isPgtOnline: order.statusList.length > 4 ? true : false,
			})
			.then((response) => {
				const { success, message } = response.data

				if (success === 1) {
					toast.success(message)
				}
				if (success === 0) {
					throw new Error(message)
				}
			})
			.catch((error) => {
				toast.error("Erro ao cancelar pedido")
			})
	}

	function openModalPix(data) {
		setOrderPix(data)
	}

	function closeModalPix() {
		setOrderPix(null)
	}

	return (
		<Container>
			<Header>
				<Link to="/profile">
					<AiOutlineArrowLeft />
				</Link>
				<h1>Meus pedidos</h1>
			</Header>

			<FiltersContainer>
				<div className="filters">
					<button
						onClick={() => setOpenModalFilters(!openModalFilters)}
						className={`button ${openModalFilters ? "active" : ""}`}
					>
						Filtrar por
						<FaAngleDown />
					</button>

					<Filters
						typeFilters={typeStatusOrders}
						isOpen={openModalFilters}
						onClose={() => setOpenModalFilters(false)}
						typeOrdersSelected={statusSelected}
						setTypeOrdersSelected={setStatusSelected}
					/>
				</div>
			</FiltersContainer>

			{isLoading === false ? (
				<>
					<ContentLastUpdate>
						{lastOrder === undefined ? (
							<></>
						) : (
							<Order
								orderParams={lastOrder}
								nameBusiness={lastOrder.nameBusiness}
								statusList={lastOrder.statusList}
								orderNumber={lastOrder.orderNumber}
								orderNumberDay={lastOrder.orderNumberDay}
								linkImage={lastOrder.linkImage}
								products={lastOrder.products}
								total={lastOrder.total}
								minTimeDelivery={lastOrder.minTimeDelivery}
								maxTimeDelivery={lastOrder.maxTimeDelivery}
								noLine={true}
								handleClickCancel={() =>
									handleClickCancel(lastOrder)
								}
								openModalPix={openModalPix}
								formatoMoeda={lastOrder.formatoMoeda}
							/>
						)}
					</ContentLastUpdate>

					<ContentHistory>
						<div className="historyLine">
							<div />
							<h2>Histórico</h2>
							<div />
						</div>

						{genericDatas && genericDatas.length > 0 ? (
							genericDatas.map((order, index) => (
								<Order
									key={index}
									orderParams={order}
									nameBusiness={order.nameBusiness}
									statusList={order.statusList}
									orderNumber={order.orderNumber}
									orderNumberDay={order.orderNumberDay}
									linkImage={order.linkImage}
									products={order.products}
									total={order.total}
									minTimeDelivery={order.minTimeDelivery}
									maxTimeDelivery={order.maxTimeDelivery}
									noLine={false}
									handleClickCancel={() =>
										handleClickCancel(order)
									}
									openModalPix={openModalPix}
									formatoMoeda={order.formatoMoeda}
								/>
							))
						) : (
							<OrdersNotFound>Nenhum pedido listado</OrdersNotFound>
						)}
					</ContentHistory>

					{data && (
						<ModalQrCodePix onClose={closeModalPix} data={orderPix} />
					)}
				</>
			) : (
				<GeneralLoader />
			)}
		</Container>
	)
}
