import styled from 'styled-components'

export const Container = styled.div`
    width: 100%;
    height: 90vh;

    display: flex;
    justify-content: center;
    align-items: center;

    form {
        max-width: 700px;
        margin: 0 auto;
        width: 90%;
        z-index: 99;
        background: #FFF;
        padding: 2rem 1rem;
        border-radius: 30px;
        min-height: 300px;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        border: 1px solid #7A05A7;
    }

    label {
        text-align: left;
        width: 100%;
        font-size: 0.9rem;
        color: var(--colorButton);
        margin: 1rem 0;
        font-weight: 700;
    }
`;

export const InputContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    
    .inputDate {
        width: 100%;
        background: none;
        padding: 0.7rem;
        outline: none;
        border-radius: 0.25rem;
        border: 1px solid #7A05A7;
        color: var(--mainTheme);

        font-weight: 500;
        font-size: 1rem;
    }
`;