import { useCallback, useEffect } from "react";
import { Card } from "./Card";
import { Container, Footer } from "./style";
import { ImageModal } from "../Modal/ImageModal/index";
import { ItemModal } from "../Modal/ItemModal/index";
import { useMenu } from "../../context/MenuContext";
import { useModalItemManager } from "../ItemModalContext";

export function MenuOne({
	productsWithGroups,
	searchTerm,
	searchResults,
	businessDatas,
	listBuying,
	handleSetListBuying,
	infoOpen,
}) {
	const {
		item,
		imageModal,
		isBuyModalOpen,
		handleSetIsBuyModalOpen,
		handleSetImageModal,
		handleSetItem,
	} = useMenu();
	const {
		handleSetSizeButtonSelected,
		handleSetSelectedOptions,
		handleSetListAssemblyItensSelected,
		handleSetTextAdditional,
	} = useModalItemManager();

	if (
		imageModal.show === true ||
		item.show === true ||
		isBuyModalOpen === true ||
		infoOpen
	) {
		document.body.style.overflow = "hidden";
	} else if (
		imageModal.show === false ||
		item.show === false ||
		isBuyModalOpen === false ||
		infoOpen === false
	) {
		document.body.style.overflow = "auto";
	}

	const detectHistory = useCallback(() => {
		handleSetIsBuyModalOpen(false);
		handleSetImageModal({ show: false });
		handleSetItem({ code: 0, show: false });

		handleSetSizeButtonSelected(0);
		handleSetTextAdditional("");
		handleSetSelectedOptions([]);
		handleSetListAssemblyItensSelected([]);
	}, [
		handleSetImageModal,
		handleSetIsBuyModalOpen,
		handleSetItem,
		handleSetListAssemblyItensSelected,
		handleSetSelectedOptions,
		handleSetSizeButtonSelected,
		handleSetTextAdditional,
	]);

	useEffect(() => {
		if (isBuyModalOpen || imageModal.show || item.show) {
			window.history.pushState({ id: 1 }, null, null);
		}
	}, [isBuyModalOpen, imageModal, item]);

	useEffect(() => {
		if (isBuyModalOpen || imageModal.show || item.show) {
			window.addEventListener("popstate", detectHistory);
		}
	}, [detectHistory, isBuyModalOpen, imageModal, item]);

	return (
		<>
			<ImageModal />

			<ItemModal
				businessDatas={businessDatas}
				listBuying={listBuying}
				handleSetListBuying={handleSetListBuying}
			/>

			<Container>
				{productsWithGroups.map((element) => (
					<Card
						key={element.idGroup}
						title={element.name}
						products={element.products}
						image={element.linkImage}
						searchTerm={searchTerm}
						searchResults={searchResults}
						businessDatas={businessDatas}
					/>
				))}
			</Container>
			<Footer />
		</>
	);
}
