import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { SmallModal } from "../../../smallModal";
import { PhoneInput } from "../../../Forms/phoneInput";
import { Form, Container } from "./styles";
import { getUF } from "./requests/getUF";
import { sendEmail } from "./requests/sendEmail";
import { saveTicket } from "./requests/saveTicket";
import { getCounty } from "./requests/getCounty";
import { Button } from "../../../Button";

// ---- Component ----
export function PartnershipContactModal({
  isPartnershipContactModalOpen,
  setIsPartnershipContactModalOpen,
}) {
  // States
  const [loading, setLoading] = useState(false);
  const [county, setCounty] = useState([]);
  const [uf, setUf] = useState([]);
  const [phone, setPhone] = useState("");

  // Handlers
  function handleCloseModal() {
    setIsPartnershipContactModalOpen(false);
  }

  // Function
  function loadCounty(uf) {
    getCounty(uf)
      .then(setCounty)
      .catch((error) => {
        console.error(`error::ce8e5efc:: ${error}`);
        toast.error("Erro ao buscar municípios.");
      });
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);

    // Get the form data
    const dataForm = new FormData(event.target);
    const formData = {
      name: dataForm.get("name"),
      email: dataForm.get("email"),
      // format phone number from (99) 99999-9999 to 99999999999
      phone: dataForm.get("phone").replace(/\D/g, ""),
      // format state from 99-UF to UF
      state: dataForm.get("state").split("-")[1],
      cdMunicipio: dataForm.get("cdMunicipio"),
      message: dataForm.get("message"),
    };

    try {
      await sendEmail(formData);
      await saveTicket(formData);
      handleCloseModal();
      toast.success("Solicitação enviada com sucesso!");
      event.target.reset();
      setPhone("");
    } catch (error) {
      console.error(`error::2109214c:: ${error}`);
      toast.error("Erro ao enviar solicitação.");
    } finally {
      setLoading(false);
    }
  }

  // Effect
  useEffect(() => {
    getUF()
      .then(setUf)
      .catch((error) => {
        console.error(`error::0e3064b9:: ${error}`);
        toast.error("Erro ao buscar estados.");
      });
  }, []);

  // Null return if modal is not open
  if (!isPartnershipContactModalOpen) return null;
  // Return
  return (
    <SmallModal title="Solicitar parceria" toClose={handleCloseModal} loading={loading}>
      <Form onSubmit={handleSubmit}>
        <Container>
          <div className="wrap">
            <div>
              <label htmlFor="name">Nome *</label>
              <input type="text" name="name" id="name" required />
            </div>
            <div>
              <label htmlFor="email">E-mail *</label>
              <input type="email" name="email" id="email" required />
            </div>
            <div>
              <label htmlFor="phone">Telefone *</label>
              <PhoneInput name="phone" id="phone" value={phone} onChange={setPhone} required />
            </div>
            <div>
              <label htmlFor="state">Estado *</label>
              <select
                name="state"
                defaultValue=""
                onChange={(e) => loadCounty(e.target.value)}
              >
                <option value="">Selecione</option>
                {uf.map((item) => (
                  <option key={item.idEstado} value={`${item.idEstado}-${item.dsUf}`}>
                    {item.dsUf}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="cdMunicipio">Cidade *</label>
              <select name="cdMunicipio" defaultValue="">
                <option value="">Selecione</option>
                {county.map((item) => (
                  <option key={item.dsMunicipio} value={item.dsMunicipio}>
                    {item.dsMunicipio}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="message">Deixe sua mensagem!</label>
              <textarea name="message" id="message" rows={4} />
            </div>
          </div>
          <Button className="submit" type="submit">
            Enviar
          </Button>
        </Container>
      </Form>
    </SmallModal>
  );
}
