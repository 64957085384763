import InputMask from "react-input-mask";

const onlyNumbers = (str) => str.replace(/[^0-9]/g, "");

export const CpfInput = ({ value, onChange, ...rest }) => {
	function handleChange(event) {
		onChange({
			...event,
			target: {
				...event.target,
				value: onlyNumbers(event.target.value),
			},
		});
	}

	return (
		<InputMask
			{...rest}
			mask="999.999.999-99"
			value={value}
			onChange={handleChange}
		/>
	);
};

export const CnpjInput = ({ value, onChange, ...rest }) => {
	function handleChange(event) {
		onChange({
			...event,
			target: {
				...event.target,
				value: onlyNumbers(event.target.value),
			},
		});
	}

	return (
		<InputMask
			{...rest}
			mask="99.999.999/9999-99"
			value={value}
			onChange={handleChange}
		/>
	);
};

export const BirthdateInput = ({ value, onChange, ...rest }) => {
	return <InputMask {...rest} mask="99/99/9999" value={value} onChange={onChange} />;
};

export const HoursInput = ({ value, onChange, ...rest }) => {
	return <InputMask {...rest} mask="99:99" value={value} onChange={onChange} />;
};

export const TelInput = ({ value, onChange, ...rest }) => {
	function handleChange(event) {
		onChange({
			...event,
			target: {
				...event.target,
				value: onlyNumbers(event.target.value),
			},
		});
	}

	return (
		<InputMask
			{...rest}
			mask="(99)99999-9999"
			value={value}
			onChange={handleChange}
		/>
	);
};

export const ZipInput = ({ value, onChange, ...rest }) => {
	function handleChange(event) {
		onChange({
			...event,
			target: {
				...event.target,
				value: onlyNumbers(event.target.value),
			},
		});
	}

	return <InputMask {...rest} mask="99999-999" value={value} onChange={handleChange} />;
};

export const CardInput = ({ value, onChange, mask, ...rest }) => {
	return (
		<InputMask type="text" mask={mask} value={value} onChange={onChange} {...rest} />
	);
};

export const RgInput = ({ value, onChange, ...rest }) => {
	function handleChange(event) {
		onChange({
			...event,
			target: {
				...event.target,
				value: onlyNumbers(event.target.value),
			},
		});
	}

	return (
		<InputMask {...rest} mask="99.999.999-9" value={value} onChange={handleChange} />
	);
};
